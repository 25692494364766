import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => sessionStorage.getItem(key),
        setItem: (key, value) => sessionStorage.setItem(key, value),
        removeItem: key => sessionStorage.removeItem(key)
      }
    })
  ],
  //while defining new states, add them to the CLEAR_ALL_STATES mutation as well
  state: {
    userInfo: {},
    assessmentId: null,
    invitedCampaignId: undefined,
    campaignId: null,
    token: null,
    userId: null,
    proctoringModelsLoaded: false,
    tabId: null,
    campaignInfo: null,
    codeVerifier:null,
    userType:null,
    session: null,
    userIntrestData: null,
    mestaCampaignDetails: {},
  },
  getters: {
    isAuthenticated: state => !!state.token,
    token: state => state.token,
    userId: state => state.userId,
    userInfo: state => state.userInfo,
    mestaCampaignDetails: state => state.mestaCampaignDetails,
    getCampaignId: state => state.campaignId,
    isModelLoaded: state => state.proctoringModelsLoaded,
    tabId: state => state.tabId,
    campaignInfo: state => state.campaignInfo,
    codeVerifier:state => state.codeVerifier,
    userType:state => state.userType,
    session:state => state.session,
    userIntrestData: state => state.userIntrestData,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    CLEAR_TOKEN(state) {
      state.token = null;
    },
    SET_USER_ID(state, userId) {
      state.userId = userId;
    },
    CLEAR_USER_ID(state) {
      state.userId = null;
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo
    },
    SET_CAMPAIGN_ID_FOR_USER(state, campaignId){
      state.userInfo.campaignId = campaignId
    },
    SET_PROCTORING_MODELS_LOADED(state, flag){
      state.proctoringModelsLoaded = flag
    },
    SET_TAB_ID(state, tabId) { // Mutation for setting tab identifier
      state.tabId = tabId;
    },
    CLEAR_TAB_ID(state) { // Mutation for clearing tab identifier
      state.tabId = null;
    },
    SET_CAMPAIGN_INFO(state, campaignInfo) {
      state.campaignInfo = campaignInfo;
    },
    SET_CODE_VERIFIER(state,codeVerifier) {
      state.codeVerifier = codeVerifier;
    },
    SET_USER_TYPE(state,userType){
      state.userType = userType;
    },
    CLEAR_USER_TYPE(state){
      state.userType = null;
    },
    SET_SESSION_KEY(state,session){
      state.session = session;
    },
    SET_MESTA_CAMPAIGN_DETAILS(state, mestaCampaignDetails){
      state.mestaCampaignDetails =  mestaCampaignDetails;
    },
    CLEAR_SESSION_KEY(state){
      state.session = null;
    },
    SET_USER_INTEREST(state, userInterest){
      state.userIntrestData = userInterest;
    },
    CLEAR_USER_INTEREST(state) {
      state.userIntrestData = null;
    },
    CLEAR_ALL_STATES(state) {
        state.userInfo= {};
        state.assessmentId= null;
        state.invitedCampaignId= undefined;
        state.campaignId= null;
        state.token= null;
        state.userId= null;
        state.proctoringModelsLoaded= false;
        state.tabId= null;
        state.campaignInfo= null;
        state.codeVerifier=null;
        state.userType=null;
        state.session= null;
        state.userIntrestData= null;
        state.mestaCampaignDetails= {};
      }
  },
  actions: {
    setToken({ commit }, token) {
      commit('SET_TOKEN', token);
    },
    clearToken({ commit }) {
      commit('CLEAR_TOKEN');
    },
    setUserId({ commit }, userId) {
      commit('SET_USER_ID', userId);
    },
    clearUserId({ commit }) {
      commit('CLEAR_USER_ID');
    },
    setUserInfo({ commit }, userInfo) {
      commit('SET_USER_INFO', userInfo);
    },
    setCampaignId({ commit }, campaignId){
      commit('SET_CAMPAIGN_ID_FOR_USER', campaignId);
    },
    setProctoringModelsLoaded({commit}, flag){
      commit('SET_PROCTORING_MODELS_LOADED', flag)
    },
    setTabId({ commit }, tabId) { // Action for setting tab identifier
      commit('SET_TAB_ID', tabId);
    },
    clearTabId({ commit }) { // Action for clearing tab identifier
      commit('CLEAR_TAB_ID');
    },
    setCampaignInfo({commit}, campaignInfo){
      commit('SET_CAMPAIGN_INFO', campaignInfo)
    },
    clearCampaignInfo({commit}){
      commit('SET_CAMPAIGN_INFO', null)
    },
    setCodeVerifier({commit}){
      commit('SET_CODE_VERIFIER', null)
    },
    setUserType({commit},userType){
      commit('SET_USER_TYPE',userType)
    },
    clearUserType({commit}){
      commit('CLEAR_USER_TYPE')
    },
    setSessionKey({commit},session){
      commit('SET_SESSION_KEY',session)
    },
    clearSessionKey({commit}){
      commit('CLEAR_SESSION_KEY')
    },
    setUserInterest({commit}, userInterest){
      commit('SET_USER_INTEREST', userInterest)
    },
    setMestaCampaignDetails({commit}, mestaCampaignDetails){
      commit('SET_MESTA_CAMPAIGN_DETAILS', mestaCampaignDetails)
    },
    clearUserInterest({commit}){
      commit('CLEAR_USER_INTEREST')
    },
    clearAllStates({commit}){
      commit('CLEAR_ALL_STATES')
    }
  },
  modules: {
  },
});
