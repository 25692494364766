import axios from 'axios'
import AuthService from '@/services/AuthService';
const instance = axios.create({
    baseURL: process.env.VUE_APP_USERS_API_URL
});

export default {
    getUserInfo: async function () {
        const userId = AuthService.getUserId()
        console.log("userId*****", userId)
        try {
            const response = await instance.get('/getUserInfo?userId=' + userId, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },


    publishResult: async function () {
        try {
            const userId = AuthService.getUserId()
            const response = await instance.get('/publishResult?userId=' + userId, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },

    getUserInfoFromResume: async function (
        path,
        fileType
    ) {
        try {
            const userId = AuthService.getUserId()
            const response = await instance.post(
                "/resume/parse",
                {
                    userId,
                    path,
                    fileType
                },
                {
                    headers: {
                        Authorization: AuthService.getToken(),
                    },
                }
            );
            return response;
        } catch (error) {
            console.log(error.data);
        }
    },

    saveAssessmentAction: async function (userId,campaignId,action) {
        try {
            const response = await instance.post(
                "/saveAssessmentAction",
                {
                    userId,
                    campaignId,
                    action
                },
                {
                    headers: {
                        Authorization: AuthService.getToken(),
                    },
                }
            );
            return response;
        } catch (error) {
            return error.response;
        }
    }
}